/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
export const UNIT_KW = 'kW';
export const CONSUMED_SETS = 'consumedSets';
export const BATTERY_SETS = 'batterySets';
export const USAGE_SETS = 'usageSets';
export const PRICE_SETS = 'priceSets';
export const CHARGE = 'charge';
export const DISCHARGE = 'discharge';

export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const longDays = [
  'Sunday ',
  'Monday ',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const GPAPH_COLORS = Object.freeze({
  imported: '#ff4400',
  exported: '#ffd701',
  consumed: '#55ff00',
  generated: '#ffffff',
  eddi: '#01ffd5',
  zappi: '#0186ff',
  libbi: '#84FF00',
  home: '#ff008c',
  charge: '#84FF00',
  discharge: '#FE9133',
  soc: '#DC0073'
});

export const GPAPH_STACKING = Object.freeze({
  imported: 'normal',
  consumed: 'normal',
  exported: undefined,
  generated: undefined,
  eddi: undefined,
  zappi: undefined,
  home: undefined,
  soc: undefined
});
