/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Period } from 'constants/enums';
import { ChargingSessions } from 'tabs/energyUsage/chargingSessions/chargingSessions.types';
import dayjs from 'utils/dayjs';

const initialState = {
  logs: {},
  filter: {
    time: {
      fromDate: dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      toDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      isActiveTimeFilter: false,
      period: Period.THIS_MONTH
    },
    usageType: '',
    friendlyNames: [],
    authorisation: undefined,
    isFilterApplied: false
  },
  activeDevice: '',
  friendlyNames: ''
};

const chargingSessionsSlice = createSlice({
  name: 'chargingSessions',
  initialState,
  reducers: {
    setChargingSessions(state, { payload }: PayloadAction<ChargingSessions>) {
      state.logs = payload;
    },

    setChargingSessionsFilters(state, { payload }) {
      state.filter = payload;
    },
    setChargingSessionsIsFilterApplied(state, { payload }) {
      state.filter.isFilterApplied = payload;
    },
    setActiveDevice(state, { payload }) {
      state.activeDevice = payload;
    },
    setFriendlyNames(state, { payload }) {
      state.friendlyNames = payload;
    },
    resetFilters(state) {
      state.filter = initialState.filter;
    }
  }
});

export const {
  setChargingSessions,
  setChargingSessionsFilters,
  setChargingSessionsIsFilterApplied,
  setActiveDevice,
  setFriendlyNames,
  resetFilters
} = chargingSessionsSlice.actions;

export const chargingSessions = chargingSessionsSlice.reducer;
