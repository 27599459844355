/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvitationStatus } from 'constants/enums';
import { isEmpty } from 'lodash-es';
import { EMPTY_LOCATION } from 'shared/js';
import { GetUserHubsAndDevicesObject, Location, LocationSlice, WeatherForecast } from 'types';

const initialState: LocationSlice = {
  sharedLocationList: {
    devices: [],
    hubs: [],
    offlineDevices: []
  },
  activeLocation: EMPTY_LOCATION, //this means current energySetup
  locations: [], // energy setups
  weatherForecast: [],
  sharedLocation: []
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setActiveLocation(state, { payload }: PayloadAction<Location>) {
      state.activeLocation = payload;
    },
    setSharedUserHubs(state, { payload }: PayloadAction<GetUserHubsAndDevicesObject>) {
      state.sharedLocationList = payload;
    },
    setLocations(state, { payload }: PayloadAction<Location[]>) {
      state.locations = [];
      payload.forEach((loc) => state.locations.push(loc));
      //if there are no locations
      if (!payload.length) {
        state.activeLocation = EMPTY_LOCATION;
        return;
      }

      let updated = false;

      //if the user has already an activeLocation set
      if (!isEmpty(state.activeLocation)) {
        const activeLocationId = state.activeLocation?.address?.id;

        const locationIndex = payload.findIndex((loc) => loc?.address?.id === activeLocationId);
        if (locationIndex !== -1) {
          state.activeLocation = payload[locationIndex];
          updated = true;
        }
      }

      //set an activeLocation by default
      if (!updated && payload?.[0].invitationData?.invitationStatus !== InvitationStatus.PENDING) {
        state.activeLocation = { ...payload?.[0] };
      }
    },
    updateLocation(state, { payload }: PayloadAction<Location>) {
      const idx = state?.locations?.findIndex(
        (location) => location?.energySetup?.id === payload?.energySetup?.id
      );
      state.locations[idx] = payload;
    },
    updateWeatherForecast(state, { payload }: PayloadAction<WeatherForecast[]>) {
      state.weatherForecast = payload;
    },
    updateUnclaimedDevices(state, { payload }: PayloadAction<number>) {
      state.activeLocation.unclaimedDevices = payload;
    }
  }
});

export const {
  setActiveLocation,
  setLocations,
  setSharedUserHubs,
  updateLocation,
  updateWeatherForecast,
  updateUnclaimedDevices
} = locationSlice.actions;
export const location = locationSlice.reducer;
