/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';
import { AccessGroup, AddDeviceToAccessGroupPayload, Pin, SetAccessGroupsPayload } from 'types';

const initialState: SetAccessGroupsPayload = {
  additionalAccessGroups: [],
  mainAccessGroup: {
    accessGroupId: '',
    deviceSerialNos: [],
    devices: [],
    name: '',
    pinConfigsPages: 0,
    authConfigs: [],
    numberOfAuthsPerDevice: []
  }
};

const accessManagementSlice = createSlice({
  name: 'accessManagement',
  initialState,
  reducers: {
    setAccessGroups(state, { payload }: PayloadAction<SetAccessGroupsPayload>) {
      state.additionalAccessGroups = payload.additionalAccessGroups;
      state.mainAccessGroup = payload.mainAccessGroup;
    },
    updateAuthValue(state, { payload }: PayloadAction<Pin>) {
      const idx = state.mainAccessGroup.authConfigs.findIndex((pin) => pin.id === payload.id);
      if (idx !== -1) {
        state.mainAccessGroup.authConfigs[idx] = {
          ...state.mainAccessGroup.authConfigs[idx],
          ...payload
        };
      }

      //Update pin in additionalAccessGroups
      state.additionalAccessGroups.forEach((accessGroup) => {
        if (!isEmpty(accessGroup.authConfigs)) {
          const idx = accessGroup.authConfigs.findIndex((pin) => pin.id === payload.id);
          if (idx !== -1) {
            accessGroup.authConfigs[idx] = { ...accessGroup.authConfigs[idx], ...payload };
          }
        }
      });
    },
    clearAccessGroups(state) {
      state.additionalAccessGroups = [];
      state.mainAccessGroup = {
        accessGroupId: '',
        deviceSerialNos: [],
        devices: [],
        name: '',
        pinConfigsPages: 0,
        authConfigs: []
      };
    },
    addAccessGroup(state, { payload }: PayloadAction<AccessGroup>) {
      state.additionalAccessGroups = [...state.additionalAccessGroups, payload];
    },
    addDeviceToAccessGroup(state, { payload }: PayloadAction<AddDeviceToAccessGroupPayload>) {
      const selectedAccessGroupIdx = state.additionalAccessGroups.findIndex(
        (accessGroup) => accessGroup.accessGroupId === payload.accessGroupId
      );
      if (selectedAccessGroupIdx !== -1) {
        payload.deviceSerialNos.forEach((deviceSerialNo) => {
          state.additionalAccessGroups[selectedAccessGroupIdx].deviceSerialNos.push(deviceSerialNo);
          state.additionalAccessGroups[selectedAccessGroupIdx].devices.push({
            serialNumber: deviceSerialNo
          });
        });
      }
    },
    removeAccessGroup(state, { payload }: PayloadAction<string>) {
      state.additionalAccessGroups = state.additionalAccessGroups.filter(
        (group) => group.accessGroupId !== payload
      );
    },
    removeDeviceFromAccessGroup(
      state,
      { payload }: PayloadAction<{ accessGroupId: string; deviceSerialNo: string }>
    ) {
      const accessGroup = state.additionalAccessGroups.find(
        (group) => group.accessGroupId === payload.accessGroupId
      );
      if (accessGroup) {
        accessGroup.devices = accessGroup.devices.filter(
          (device) => device.serialNumber !== payload.deviceSerialNo
        );
        accessGroup.deviceSerialNos = accessGroup.deviceSerialNos.filter(
          (deviceSerialNo) => deviceSerialNo !== payload.deviceSerialNo
        );

        if (isEmpty(accessGroup.devices)) {
          state.additionalAccessGroups = state.additionalAccessGroups.filter(
            (group) => group.accessGroupId !== payload.accessGroupId
          );
        }
      }
    }
  }
});

export const {
  setAccessGroups,
  updateAuthValue,
  clearAccessGroups,
  addAccessGroup,
  addDeviceToAccessGroup,
  removeAccessGroup,
  removeDeviceFromAccessGroup
} = accessManagementSlice.actions;

export const accessManagement = accessManagementSlice.reducer;
