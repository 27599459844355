/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { buildOtherYearList, buildYears, NONE_OPTION, VEHICLE_OWNERSHIP } from 'shared/js';
import { UserVehicle, UserVehicleReq } from 'types';
import uuid from 'uuid';

import { ProcessVehicleDataAccumulator } from './processors.types';

const OTHER = 'Other';

export const processVehicleOwnership = (val: number) => {
  switch (val) {
    case 1:
      return VEHICLE_OWNERSHIP.Personal;
    case 2:
      return VEHICLE_OWNERSHIP.Fleet;
    default:
      return NONE_OPTION;
  }
};

export const processVehicleOwnershipOut = (val: string) => {
  switch (val) {
    case VEHICLE_OWNERSHIP.Personal:
      return 1;
    case VEHICLE_OWNERSHIP.Fleet:
      return 2;
    default:
      return 0;
  }
};

export const processAllVehicleData = (arr: UserVehicleReq[]) => {
  if (!arr) return [];

  return arr.map((item) => {
    const {
      id,
      vehicleNickName,
      model,
      batterySize,
      manufacturerOfficialEVRange,
      manufacturingYear,
      ownership,
      chargeRate,
      realEVRange,
      manufacturer,
      refId
    } = item;

    return {
      id,
      vehicleNickName,
      model,
      batterySize,
      manufacturerOfficialEVRange,
      manufacturingYear,
      ownership: processVehicleOwnership(ownership),
      chargeRate,
      realEVRange,
      manufacturer,
      refId
    };
  });
};

export const processUserVehicle = ({
  id,
  vehicleNickName,
  model,
  batterySize,
  manufacturerOfficialEVRange,
  manufacturingYear,
  ownership,
  condition,
  chargeRate,
  realEVRange,
  manufacturer,
  refId
}: UserVehicleReq) => {
  return {
    id,
    vehicleNickName,
    model,
    condition,
    batterySize: +Number(batterySize).toFixed(1),
    manufacturerOfficialEVRange,
    manufacturingYear,
    ownership: processVehicleOwnership(ownership),
    chargeRate: +Number(chargeRate).toFixed(2),
    realEVRange,
    manufacturer,
    refId
  };
};

export const processManufacturersList = (arr: string[]) => {
  if (!arr) return [];
  const OTHER_MANUFACTURER = {
    label: OTHER,
    value: OTHER
  };
  const list = arr.map((vehicle) => ({ label: vehicle, value: vehicle }));
  const listSorted = list.map((vehicle) => vehicle.label).sort();
  const processedManufacturers = list.sort(
    (a, b) => listSorted.indexOf(a.label) - listSorted.indexOf(b.label)
  );
  processedManufacturers.push(OTHER_MANUFACTURER);
  return processedManufacturers;
};

export const processVehicleData = (arr: UserVehicle[]) => {
  if (!arr) return {};
  const initialAc: ProcessVehicleDataAccumulator = {};
  // create the object map
  // otherwise it will return undefined when
  // adding more to each item
  const objmap = arr.reduce((ac, cv) => {
    ac[cv.manufacturer] = [];
    return ac;
  }, initialAc);

  const OTHER_MODEL = {
    label: OTHER,
    value: OTHER,
    data: {
      model: OTHER,
      availabilityDateFrom: new Date().getUTCFullYear() - 10,
      availabilityDateTo: new Date().getUTCFullYear(),
      batterySize: 0,
      manufacturerOfficialEVRange: 0,
      realEVRange: 0,
      chargeRate: 0,
      condition: 0,
      ownership: NONE_OPTION
    },
    years: buildOtherYearList()
  };
  const OTHER_MANUFACTURER = [
    { ...OTHER_MODEL, data: { ...OTHER_MODEL.data, id: uuid.v4(), manufacturer: OTHER } }
  ];

  // use the map to build brand with
  // array of models, that can be used in
  // list dropdown and also exact model
  // data from it
  const manufacturers = new Set<string>();
  const vehicleData = arr.reduce((ac, cv) => {
    ac[cv.manufacturer] = [
      ...ac[cv.manufacturer],
      {
        label: cv.model,
        value: cv.model,
        data: { ...cv },
        years: buildYears(cv.availabilityDateFrom, cv.availabilityDateTo) || []
      }
    ];
    manufacturers.add(cv.manufacturer);
    return ac;
  }, objmap);
  const vehicleDataWithOther: ProcessVehicleDataAccumulator = vehicleData;
  Array.from(manufacturers).forEach((manufacturer) => {
    vehicleDataWithOther[manufacturer].push({
      ...OTHER_MODEL,
      data: {
        ...OTHER_MODEL.data,
        id: uuid.v4(),
        manufacturer
      } as UserVehicle
    });
  });
  return { ...vehicleDataWithOther, Other: OTHER_MANUFACTURER };
};

export const generateVehicleOptionList = (vehicles: UserVehicle[]) =>
  vehicles.map((elem) => ({ value: elem, label: elem.vehicleNickName }));
