/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Dayjs } from 'dayjs';
import dayjs from 'utils/dayjs';

export const getDateAndTime = (timestamp: string, dateFormat: string) => {
  if (!timestamp) return { day: null, hour: null };

  /** 
   * ATTOW, chargelog times are sent to the server in packets in the device's
   * local time, but the server stores them as if they are in the server's local
   * time, which will probably be UTC. So here we are checking if 'Z' exists on
   * the end of the timestamp (indicates timestamp as fetched from the DB is in
   * UTC), and removing it before creating a new Date from the timestamp,
   * effectively converting the time to the browser's local time, which should
   * hopefully match the device TZ most of the time.
   */
  const date =
    timestamp?.slice(-1) === 'Z' ? new Date(timestamp?.slice(0, -1)) : new Date(timestamp);

  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const isMetric = dateFormat.toLowerCase()[0] === 'd';

  return {
    day: (isMetric ? dd + '-' + mm : mm + '-' + dd) + '-' + yyyy,
    hour: date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2)
  };
};

export const getHoursAndMinutesFromMiliseconds = (miliseconds: number) => {
  const seconds = ~~(miliseconds / 1000);
  const minutes = ~~((seconds % 3600) / 60);
  const hours = ~~(seconds / 3600);

  return `${hours}h ${minutes}m`;
};

export const getHoursAndMinutesFromMinutes = (minutes: number) => {
  const hours = ~~(minutes / 60);
  const remainingMinutes = ~~(minutes % 60);

  return `${hours}h ${remainingMinutes}m`;
};

export const getMomentForStartingCalendar = (specificMoment: Dayjs) => {
  const oneYearAgo = dayjs().subtract(1, 'year');

  if (specificMoment.isBefore(oneYearAgo)) {
    return specificMoment;
  } else {
    return oneYearAgo;
  }
};
